<template>
  <div class="gonggaoxq">
    <div class="header">
    </div>
    <div class="content">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="mbx">
        <el-breadcrumb-item to="/financing">融资服务</el-breadcrumb-item>
        <el-breadcrumb-item>详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="flex">
        <div class="xq flex-1">
          <el-descriptions class="margin-top"  :column="1" >
            <el-descriptions-item label="标题">{{datas.title}}</el-descriptions-item>
            <el-descriptions-item label="详细需求"><div v-html="datas.notes"></div></el-descriptions-item>
            <el-descriptions-item label="联系人">{{ datas.contact }}</el-descriptions-item>
            <el-descriptions-item label="联系人电话">{{ datas.phone }}</el-descriptions-item>
            <el-descriptions-item label="附件">
              <fujian  :files="datas.file"></fujian>
            </el-descriptions-item>
          </el-descriptions>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import fujian from '../common/fujian.vue'
import {warehousedetail} from "../../request/moudle/logistics";
import {financingdetail} from "../../request/moudle/financing";
export default {
  name: "gonggaoxq",
  components:{
    fujian
  },
  data(){
    return {
      datas:''
    }
  },
  created() {
    this.loadxq()
  },
  methods:{
    loadxq(){
      this.$api.financing.financingdetail({
        id:this.$route.query.id,
      }).then((res)=>{
          this.datas=res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-descriptions-item__label{
  min-width: 80px;
}
.width300{
  width:300px ;
  margin-left:65px;
}
  .gonggaoxq{
     background: #F5F5F5;
    .fujian{
      margin-top: 50px;
      .as{
        line-height: 25px;
        color:  #409EFF;
      }
    }
    .header{
      height: 180px;
      background: url("../../assets/gxjy/gxjy.png") no-repeat;
      background-size: 100% 100%;
      padding-top: 90px;
    }
    .content{
      width: 1200px;
      margin: 20px auto;
      .xq{
        background: #fff;
        margin-top: 20px;
        padding: 20px;
      }
      .title{
        color: #292929;
        text-align: center;
        font-weight: bolder;
        font-size: 20px;
      }
      .h1{
        line-height: 50px;
        text-align: center;
        span{
          margin-right: 50px;
        }
      }
    }
  }
</style>